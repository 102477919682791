import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
function _createMdxContent(props) {
  const _components = {
    a: "a",
    p: "p",
    strong: "strong",
    ..._provideComponents(),
    ...props.components
  };
  return _jsxs(_Fragment, {
    children: [_jsxs(_components.p, {
      children: [_jsx(_components.a, {
        href: "https://dbc-c446b955-5a46.cloud.databricks.com/?o=2538143864610082#notebook/3110557124285919",
        children: _jsx(_components.strong, {
          children: "Bugs are reported here"
        })
      }), ". Simply press \"Run all\" if you don't know what you're doing, or you can run individual commands separately."]
    }), "\n", _jsx(_components.p, {
      children: "This is a Databricks notebook, so we can do arbitrary queries on our data. It comes at basically no additional cost to our existing data pipeline."
    })]
  });
}
export default function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = {
    ..._provideComponents(),
    ...props.components
  };
  return MDXLayout ? _jsx(MDXLayout, {
    ...props,
    children: _jsx(_createMdxContent, {
      ...props
    })
  }) : _createMdxContent(props);
}
export const meta = () => JSON.parse('{"title":[null,"Blitz Docs"],"description":"Docs for Blitz App"}');
